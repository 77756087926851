// variables
$purple: #65328F
$white: #fff
$green: #33BDB5
$pink: #CF3C71
$pink-hover: #a62b58
$grey: #3E474F
$new-purple:#443871
$purple-title: #573B85
$grey-light: #525B63
.white-color
  color: #fff
.black-color
  color: #000

.white-bg
  background-color: #fff !important

.gray-bg
  background-color: #eaeaea

.silver-bg
  background-color: #ABABAB

.green-bg
  background-color: #33BDB5 !important
  transition: 0.3s all

.green-color
  color: #33BDB5 !important

.green-bg-hover
  &:hover
    background-color: #2b9f99 !important
    transition: 0.3s all

.pink-color
  color: $pink

.pink-cookie-bg
  background-color: #EB5EA5 !important

.pink-bg
  background-color: $pink !important
  transition: 0.3s all

.purple-bg
  background-color: $new-purple !important
  transition: 0.3s all

.pink-bg-hover
  &:hover
    background-color: #a62b58 !important
    transition: 0.3s all

.yellow-color
  color: #DDAE16

.purple-color
  color: $new-purple

.yellow-bg
  background-color: #DDAE16 !important
  transition: 0.3s all

.yellow-bg-hover
  &:hover
    background-color: #b79010 !important
    transition: 0.3s all

.purple-bg
  background-color: #443871 !important
  transition: 0.3s all

.purple-bg-hover
  &:hover
    background-color: #462263 !important
    transition: 0.3s all

.carbon-bg
  background-color: #424f55 !important
  transition: 0.3s all

.carbon-bg-hover
  &:hover
    background-color: #1a2023 !important
    transition: 0.3s all

.red-bg
  background-color: red !important
  transition: 0.3s all

.red-bg-hover
  &:hover
    background-color: #cb0000 !important
    transition: 0.3s all

.bg-gradient-1
  background: #01f6ad !important
  background: -moz-linear-gradient(left,#01f6ad 0%,#3e71b8 100%) !important
  background: -webkit-linear-gradient(left,#01f6ad 0%,#3e71b8 100%) !important
  background: linear-gradient(to right,#01f6ad 0%,#3e71b8 100%) !important
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#01f6ad', endColorstr='#3e71b8', GradientType=1) !important

.bg-gradient-2
  background: #2bbef4 !important
  background: -moz-linear-gradient(left,#2bbef4 0%,#d1277f 100%) !important
  background: -webkit-linear-gradient(left,#2bbef4 0%,#d1277f 100%) !important
  background: linear-gradient(to right,#2bbef4 0%,#d1277f 100%) !important
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2bbef4', endColorstr='#d1277f', GradientType=1) !important

.bg-gradient-3
  background: #f1e646 !important
  background: -moz-linear-gradient(left,#f1e646 0%,#30fada 100%) !important
  background: -webkit-linear-gradient(left, #f1e646 0%, #30fada 100%) !important
  background: linear-gradient(to right,#f1e646 0%,#30fada 100%) !important
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1e646', endColorstr='#30fada', GradientType=1) !important

.bg-gradient-4
  background: #f30d73 !important
  background: -moz-linear-gradient(45deg,#f30d73 0%,#4d3bd9 100%) !important
  background: -webkit-linear-gradient(45deg,#f30d73 0%,#4d3bd9 100%) !important
  background: linear-gradient(45deg,#f30d73 0%,#4d3bd9 100%) !important
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f30d73', endColorstr='#4d3bd9', GradientType=1) !important

.bg-gradient-5
  background: #ffc193
  background: -moz-linear-gradient(left, #ffc193 0%, #ff0461 100%)
  background: -webkit-linear-gradient(left, #ffc193 0%, #ff0461 100%)
  background: linear-gradient(to right, #ffc193 0%, #ff0461 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc193', endColorstr='#ff0461', GradientType=1)

.bg-gradient-6
  background: #ffdf39
  background: -moz-linear-gradient(left, #ffdf39 0%, #ff2b27 100%)
  background: -webkit-linear-gradient(left, #ffdf39 0%, #ff2b27 100%)
  background: linear-gradient(to right, #ffdf39 0%, #ff2b27 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdf39', endColorstr='#ff2b27', GradientType=1)

.bg-gradient-7
  background: #9e2ede
  background: -moz-linear-gradient(left, #9e2ede 0%, #1966d2 100%)
  background: -webkit-linear-gradient(left, #9e2ede 0%, #1966d2 100%)
  background: linear-gradient(to right, #9e2ede 0%, #1966d2 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9e2ede', endColorstr='#1966d2', GradientType=1)

.bg-gradient-8
  background: #03bafc
  background: -moz-linear-gradient(left, #03bafc 0%, #3d24be 100%)
  background: -webkit-linear-gradient(left, #03bafc 0%, #3d24be 100%)
  background: linear-gradient(to right, #03bafc 0%, #3d24be 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03bafc', endColorstr='#3d24be', GradientType=1)

.bg-gradient-9
  background: #01fefd
  background: -moz-linear-gradient(left, #01fefd 0%, #92298f 100%)
  background: -webkit-linear-gradient(left, #01fefd 0%, #92298f 100%)
  background: linear-gradient(to right, #01fefd 0%, #92298f 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#01fefd', endColorstr='#92298f', GradientType=1)

.bg-gradient-10
  background: #3C464F

.bg-gradient-11
  background: #F2F2F2

.bg-gradient-12
  background: #B2C906

.bg-rainbow
  padding: 2px
  position: relative
  background: rgb(255, 0, 138)
  background: -moz-linear-gradient(left,rgba(255, 0, 138, 1) 0%,rgba(0, 195, 173, 1) 50%,rgba(238, 165, 0, 1) 100%)
  background: -webkit-linear-gradient(left,rgba(255, 0, 138, 1) 0%,rgba(0, 195, 173, 1) 50%,rgba(238, 165, 0, 1) 100%)
  background: linear-gradient(to right,rgba(255, 0, 138, 1) 0%,rgba(0, 195, 173, 1) 50%,rgba(238, 165, 0, 1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff008a', endColorstr='#eea500', GradientType=1)
  border-radius: 10px

.bg-gradient-done
  background: #693894
  background: -moz-linear-gradient(left, #693894 0%, #AC33AC 100%)
  background: -webkit-linear-gradient(left, #693894 0%, #AC33AC 100%)
  background: linear-gradient(to right, #693894 0%, #AC33AC 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#693894', endColorstr='#AC33AC', GradientType=1)

.bg-gradient-error
  background: #E8318A
  background: -moz-linear-gradient(left, #E8318A 0%, #AC3433 100%)
  background: -webkit-linear-gradient(left, #E8318A 0%, #AC3433 100%)
  background: linear-gradient(to right, #E8318A 0%, #AC3433 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E8318A', endColorstr='#AC3433', GradientType=1)

.gradient-button-purple
    background: linear-gradient(90deg, #693894 11%, #AC33AC 94%)

