// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap')
@import 'colors'

@font-face
  font-family: 'Montserrat'
  src: url('../assets/fonts/montserrat-regular.woff2') format('woff2')
  font-weight: 400
  font-style: normal
  font-display: swap


@font-face
  font-family: 'Montserrat'
  src: url('../assets/fonts/montserrat-medium.woff2') format('woff2')
  font-weight: 500
  font-style: normal
  font-display: swap


@font-face
  font-family: 'Montserrat'
  src: url('../assets/fonts/montserrat-semibold.woff2') format('woff2')
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Montserrat'
  src: url('../assets/fonts/montserrat-ExtraBold.woff2') format('woff2')
  font-weight: 700
  font-style: normal
  font-display: swap


.bold
  font-weight: 600

html
  font-family: 'Montserrat', sans-serif

h1
  font-size: 1.4rem
h2
  font-size: 1.375rem

.paragrap
  font-size: 1rem
  color: $grey-light



.title_h2
  margin: 0
  text-align: center
  color: $grey
  font-weight: 600
  padding: 1rem 0
  @media (max-width: 768px)
      font-size: 1.1rem
  @media (min-width: 769px) and (max-width: 1024px)
      font-size: 1.2rem
  @media (min-width: 1025px)
      font-size: 1.5rem

// font-size-variable
$font-size-h1: 1.4rem
$font-size-h2: 1.2rem
$font-size-h2-md: 1.5rem
$font-size-h2-lg: 1.7rem
$font-size-h2-xl: 2rem
