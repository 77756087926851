// body
//   font-family: 'Quicksand', sans-serif
//   margin: 0

  /* Archivo styles.css o styles.scss */

// remueve el destello en los enlaces de aplicación móvil
a
  -webkit-tap-highlight-color: transparent !important

input
  -webkit-tap-highlight-color: transparent !important

/* Evitar zoom táctil en dispositivos móviles */
body
  touch-action: manipulation

/* Bloquear el zoom general en la página */
html
  user-zoom: fixed

.secondary-link
  font-style: normal
  font-weight: 600
  font-size: 0.9rem
  line-height: 24px
  text-decoration-line: underline
  color: #0A66C2

.center-content
  height: calc(100dvh - 140px)
  margin: 0 !important

.no-border
  border: none !important

input,
button,
textarea
  font-family: "Montserrat", sans-serif !important

.blocked-input
  pointer-events: none
  cursor: not-allowed

.alternative-input
  background-color: #eaeaea !important
  border: none !important

.justify-content-space-around
  justify-content: space-around !important

.capitalize-first-word
  &:first-letter
    text-transform: uppercase !important

.fit-content
  width: fit-content !important

.flex-grow-10
  flex-grow: 10 !important

.flex-direction-row-reverse
  flex-direction: row-reverse !important

.font-size-small-for-button
  font-size: 0.5rem !important
  img
    margin-left: 0.2rem !important

.position-relative
  position: relative !important

.position-absolute
  position: absolute !important

.font-600
  font-weight: 600 !important

.row
  display: flex
  flex-wrap: wrap

.border-none
  border: 0 !important

.circle-element-full
  border-radius: 100% !important

.d-flex
  display: flex

.justify-content-center
  justify-content: center !important

.justify-content-start
  justify-content: start !important

.justify-content-end
  justify-content: end !important

.flex-direction-row-reverse
  flex-direction: row-reverse !important

.flex-direction-column
  flex-direction: column !important

.justify-content-space-around
  justify-content: space-around !important

.align-items-center
  align-items: center

.m-auto
  margin: 0 auto !important

.d-none
  display: none !important

.d-block
  display: block

.object-fit-contain
  object-fit: contain

.object-fit-cover
  object-fit: cover

.w-100
  width: 100% !important

.vh-100
  height: 100vh !important

.h-100
  height: 100% !important

.no-margin
  margin: 0 !important

.text-center
  text-align: center !important

.text-uppercase
  text-transform: uppercase !important

.disable
  opacity: 0.5
  pointer-events: none
  transition: 0.3s all
  background-color: gray !important
  color: black

.disable-input
  opacity: 0.5
  pointer-events: none
  transition: 0.3s all
  background-color: #eaeaea !important
  color: black

.uppercase
  text-transform: uppercase

.text-align-center
  text-align: center !important

// input-form
input,
textarea,
select
  width: 100%
  padding: 0.7rem 1rem
  border-radius: 0.4rem
  border: 1px solid #eaeaea
  display: block
  width: -moz-fit-content
  transition: 0.3s all
  font-weight: 500
  font-size: 1rem
  box-sizing: border-box
  @media (max-width: 768px)
    font-size: 0.8rem !important

.padding-element
  padding: 0.7rem
  box-sizing: border-box

.max-width-100
  max-width: 100% !important

.medium-spacing
  margin: 1rem

.small-spacing
  margin: 0.5rem

.extra-small-spacing
  margin: 0.25rem

.extra-small-spacing-1
  margin: 0.1rem

.extra-small-spacing-2
  margin: 0.2rem

footer
  z-index: 900
  position: relative

.swiper-pagination-bullet-active
  background-color: #000 !important

.show-hide-password
  position: absolute
  top: 0
  right: 1rem
  height: 50px
  width: auto
  display: flex
  align-items: center
  cursor: pointer
  & img
    width: 20px
  @media (max-width: 767px)
    top: -0.2rem

.btn
  font-family: 'Montserrat', sans-serif
  padding: 0.6rem 0.8rem
  background: gray
  text-decoration: none
  border-radius: 0.4rem
  display: block
  width: fit-content
  transition: 0.3s all
  font-weight: 600
  font-size: 0.7rem
  text-align: center
  cursor: pointer
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  box-sizing: border-box
  border: 0
  min-width: 150px
  display: flex
  align-items: center
  justify-content: center

.big-btn-font
  font-size: 0.8rem

.btn-rounded
  border-radius: 50px

.input-with-margin
  width: 100% !important
  color: red
  font-size: 0.75rem
  font-weight: 600
  position: relative
  top: -10px
  padding-left: 2rem

.float-right
  float: right !important

.float-left
  float: left !important

.logo-forms
  width: 200px

.width-fit-content
  width: fit-content !important

.font-500
  font-weight: 500 !important

// remove border from any link withouth input
:not(input):focus-visible
  outline: 0 !important

:not(input):focus-visible
  outline-offset: 0 !important

.imgCross
  position: absolute
  right: 1rem
  top: 1rem
  width: 1.2rem !important
  height: 1.2rem !important
  cursor: pointer

.no-increase-decrease[type=number]::-webkit-inner-spin-button,
.no-increase-decrease[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

// notifications container
.notifications
  position: fixed
  right: 1.4rem
  top: 1.4rem
  height: 100%
  overflow: scroll
  z-index: 2
  div
    margin-bottom: 1rem

.notifications-container-top
  top: 100px

.relative
    position: relative

#app-global-bottom-spacing
    height: 80px
    width: 100%
