.large-container
  max-width: 1280px
  width: 100%
  padding: 0 1rem
  box-sizing: border-box

.medium-container
  max-width: 768px
  width: 100%
  width: 100%
  padding: 0 1rem
  box-sizing: border-box

.small-container
  max-width: 600px
  width: 100%
  width: 100%
  box-sizing: border-box
  @media (max-width: 635px)
    padding: 0 1.4rem
  @media (max-width: 360px)
    padding: 0 10px


.extra-small-container
  max-width: 400px
  width: 100%
  width: 100%
  padding: 0 1.4rem
  box-sizing: border-box

.small-container-no-padding
  max-width: 600px
  width: 100%
  width: 100%

.container
  max-width: 1366px
  @media (max-width: 1366px)
    padding: 0 1.4rem
