#authentication
    .container-icon-circle
        padding: 3rem
        border: 1px solid #EAEAEA
        width: fit-content
        border-radius: 50%
        box-sizing: border-box
        width: 150px
        height: 150px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        img
            width: 50px
            height: auto
    h1
        font-size: 1.4rem
        line-height: 2.4rem
        margin: 0.5rem 0
    h2
        font-size: 1rem
    p
        font-size: 0.9rem
    .secondary-link
        color: #0A66C2
        font-size: 0.8rem
    form
        label
            font-size: 0.8rem
            font-weight: 500
            color: #8E8E8E
        input
            margin-top: 0.5rem
    .btn-login-secondary
        padding: 0.5rem 1.5rem
        box-sizing: border-box
        width: 100%
        margin-bottom: 1rem
        border-radius: 50px !important
        border: 1px solid #bdbdbd
        font-size: 0.9rem
        cursor: pointer
        font-weight: 600
        color: #000
        background-color: #fff
        display: flex
        align-items: center
        justify-content: center
        transition: 0.3s all
        &:hover
            background-color: #f5f5f5
            transition: 0.3s all
        img
            width: 20px
            height: auto
            margin-right: 0.5rem
    .back-button
        position: absolute
        top: 0
        left: 0
        img
            width: 1.5rem

.back-button-in-large-container
    left: 2.5rem !important
    top: 4rem !important
    @media(max-width: 468px)
        left: 1.4rem !important
        top: 2rem !important
    .separator
        display: flex
        justify-content: center
        align-items: center
        color: #8E8E8E
        font-weight: 500
        .line
            width: 100%
            height: 1.4px
            background-color: #b9b9b9
    .gray-color
        color: #8E8E8E
