@import 'styles/font'
@import 'styles/general'
@import 'styles/colors'
@import 'styles/grid-system'
@import 'styles/containers'
@import 'styles/authentication'

body
  margin: 0
  font-family: "Montserrat", sans-serif
h1,h2,h3,h4
    font-weight:600
.m-0
  margin: 0 !important

.mt-1
    margin-top: 1rem !important

.mt-0
    margin-top: 0 !important

.box-sizing-border-box
  box-sizing: border-box !important

.r-0
  right: 0

.border-solid
  border-width: 2px !important

main
  max-width: 700px
  width: auto
  margin: 0 auto

.border-dark::-webkit-input-placeholder
  color: var(--main-black-color)

.border-dark::-moz-placeholder
  color: var(--main-black-color)

.border-dark:-ms-input-placeholder
  color: var(--main-black-color)

.border-dark:-moz-placeholder
  color: var(--main-black-color)

.title-bar
  min-height: 55px

.round
  border-radius: 18px !important

.border.border-default
  border-color: #3dbeb8 !important
  box-shadow: 0 0px 3px #3dbeb8 !important
  color: #3dbeb8

.border-deafult:not(.border)
  border: 1px solid #31bcb5
  color: #31bcb5

.bookmark-tr
  position: absolute
  top: 5px
  right: 5px

  &>div
    position: relative

  &:before
    content: ""
    position: absolute
    top: 2px
    right: 2px
    bottom: 2px
    left: 2px
    background: var(--main-white-color)
    border-radius: 10px

.cursor-pointer
  cursor: pointer !important

a
  color: #33BDB5
  transition: 0.3s all

// a:hover
//   color: #1e7470
//   transition: 0.3s all

input,
select
  padding: 0.8rem 1.5rem
  box-sizing: border-box
  width: 100%
  margin-bottom: 1rem
  border-radius: 0.3rem
  border: 1px solid #bdbdbd
  font-size: 1rem
  font-family: "Montserrat", sans-serif !important

/* Estilos generales para input y select */
input, select
  padding: 0.8rem 1.5rem
  box-sizing: border-box
  width: 100%
  margin-bottom: 1rem
  border-radius: 0.3rem
  border: 1px solid #bdbdbd
  font-size: 1rem

select
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  padding: 0.8rem 1.5rem
  box-sizing: border-box
  width: 100%
  margin-bottom: 1rem
  border-radius: 0.3rem
  border: 1px solid #bdbdbd
  font-size: 1rem

.spacing
  padding-bottom: 2rem

.button-default
  padding: 0.7rem 1.4rem
  cursor: pointer
  font-size: 1rem
  border-radius: 0.3rem
  box-sizing: border-box
  border: 0

.back-button
  background-color: transparent
  border: 0
  cursor: pointer

  & img
    height: auto
    width: 1rem

textarea:focus,
input:focus,
input[type]:focus
  outline-color:  #E5A832
  outline: 2

.row
  display: flex
  flex-wrap: wrap

  &-no-flex-wrap
    display: flex

.iwanted-bg
  background-color: #00c3bb
  transition: all ease .3s

.ihaveit-bg
  background-color: #443570
  transition: all ease .3s

.helpyou-bg
  background-color: #e3a43b
  transition: all ease .3s

.helpme-bg
  background-color: #c44070
  transition: all ease .3s

.green-color
  color: #33BDB5

.green-bg
  background-color: #33BDB5

.black-bg
  background-color: #525B63

.white-color
  color: white

.gray-color
  color: #424F55

.purple-color
  color: #65328F

.purple-bg
  background-color: #443871

.purple-light-bg
  background-color: #E8DBFF

.purple-light-color
  color: #E8DBFF

swiper-container
    width: 100%
    &::part(bullet-active)
        background-color: #65328F

.skeleton
    -webkit-animation: skeleton-loading 1s linear infinite alternate
    animation: skeleton-loading 1s linear infinite alternate
    background-color: #f0f3f5

.skeleton-rounded
    border-radius: .5rem


@-webkit-keyframes skeleton-loading
    0%
        background-color: #c2cfd6
    100%
        background-color: #f0f3f5
